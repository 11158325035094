import request from '@/services/request.js'
function targetUrl(url) {
  return '/location/vip' + url
  // return '/testlocation/vip' + url;
  // return url;
}
function targetUrlNew(url) {
  return '/location/vipv2' + url
  // return '/testlocation/vip' + url;
  // return url;
}
export function togetherTestUrl(url) {
  return '/location/vip' + url
  // return url;
}
function lookLoan(url) {
// return '/wydk' + url;
  return url
}
// 海威电影票，手机，视频充值订单
export function cinema(url) {
  return '/cinema' + url
  // return url;
}
// 生活充值首页
export function getLifeTopUpData(data) {
  return request({
    url: targetUrl('/vcard/newLifeCharge'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 生活充值首页-cps
export function getHomeList(data) {
  return request({
    url: targetUrlNew('/recharge/getHomeList'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/recharge/getHomeList',
    method: 'get',
    data,
    prefix: ''
  })
}
// 视频充值详情
export function getVideoTopUpData(data) {
  return request({
    url: targetUrl('/vcard/cardInfo'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 视频充值详情-cps
export function getVideoInfoData(data) {
  return request({
    url: targetUrlNew('/recharge/video/getInfo'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/recharge/video/getInfo',
    method: 'post',
    data,
    prefix: ''
  })
}
// 视频充值支付
export function getVideoTopUpPay(data) {
  return request({
    url: targetUrl('/vcard/newCardPay'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 手机充值详情
export function getMobileTopUpData(data) {
  return request({
    url: targetUrl('/rechargeMobile/newList'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 手机充值详情-cps
export function getMobileListData(data) {
  return request({
    url: targetUrlNew('/recharge/mobile/getList'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/recharge/mobile/getList',
    method: 'get',
    data,
    prefix: ''
  })
}

// 手机充值支付
export function getMobileTopUpPay(data) {
  return request({
    url: targetUrl('/rechargeMobile/newPay'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 小桔加油---油站列表
export function getXJComeOnList(data) {
  return request({
    url: targetUrlNew('/fuel/xj/v1/list'),
    // url: targetUrlNew('/fuel/v3/list'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/location/vipv2/fuel/xj/v1/list',
    method: 'post',
    data
  })
}
// 小桔加油---油站详情
export function getXJComeOnDetail(data) {
  return request({
    // url: targetUrl('/fuel/v3/detail'),
    url: targetUrlNew('/fuel/xj/v1/detail'),
    method: 'post',
    data
  })
}
// 小桔加油---支付前确认信息
export function getXJComeOnPayData(data) {
  return request({
    // url: targetUrl('/fuel/v3/realPrice'),
    url: targetUrlNew('/fuel/xj/v1/realPrice'),
    method: 'post',
    data
  })
}
// 小桔加油---支付提交订单
export function getXJComeOnSubmitOrders(data) {
  return request({
    url: targetUrlNew('/fuel/xj/v1/createOilOrder'),
    // url: targetUrl('/fuel/v3/createOilOrder'),
    method: 'post',
    data
  })
}
// 小桔加油---支付下单
export function getXJComeOnPay(data) {
  return request({
    url: targetUrl('/fuel/v3/rePay'),
    method: 'post',
    data
  })
}
// 小桔加油---帮助中心
export function getXJHelpList(data) {
  return request({
    url: targetUrl('/fuel/v3/getHelpList'),
    method: 'get',
    params: data,
    prefix: ''
  })
}
// 小桔加油---帮助详情
export function getXJHelpInfo(data) {
  return request({
    url: targetUrl('/fuel/v3/getHelpInfo'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 小桔加油---优惠规则
export function getXJRulesInfo(data) {
  return request({
    // url: targetUrl('/fuel/v3/getDiscountRule'),
    url: targetUrlNew('/fuel/xj/v1/getDiscountRule'),
    method: 'get',
    params: data
  })
}
// 小桔登录
export function getXJValidationInfo(data) {
  return request({
    url: targetUrl('/hirePurchase/jdd/jddToken'),
    method: 'post',
    data
  })
}

// 小桔加油---领券接口
export function getPromotion(data) {
  return request({
    url: targetUrl('/fuel/v3/getPromotion'),
    method: 'post',
    data
  })
}

// 小桔加油---券查询接口
export function getPromotionList(data) {
  return request({
    url: targetUrl('/fuel/v3/getUserPromotionList'),
    method: 'post',
    data
  })
}

// 小桔加油---查询用户可用券信息(下单前)
export function getOrderPromotionList(data) {
  return request({
    url: targetUrlNew('/fuel/xj/v1/getOrderPromotionList'),
    method: 'post',
    data
  })
}

// 小桔加油---是否可以使用优惠券
export function isPromotion(data) {
  return request({
    url: targetUrl('/fuel/v3/isPromotion'),
    method: 'post',
    data
  })
}

// 小桔加油---判断是否显示领取框
export function isDisplay(data) {
  return request({
    url: targetUrl('/fuel/v3/isDisplay'),
    method: 'post',
    data
  })
}

// 橘子分期获取路径
export function getLoanUrl(data) {
  return request({
    url: targetUrl('/loan/jz/loanUrl'),
    method: 'post',
    data
  })
}
// 橘子分期完善信息
export function perfectLoanInfo(data) {
  return request({
    url: targetUrl('/loan/jz/jddToken'),
    method: 'post',
    data
  })
}
// 油站列表
export function getComeOnList(data) {
  return request({
    url: targetUrl('/fuel/v2/list'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 加油站详情
export function getComeOndetail(data) {
  return request({
    url: targetUrl('/fuel/v2/detail'),
    method: 'post',
    data,
    prefix: ''
  })
}
// 帮助中心
export function getHelpList(data) {
  return request({
    url: targetUrl('/fuel/v2/getHelpList'),
    method: 'get',
    params: data,
    prefix: ''
  })
}
// 帮助详情
export function getHelpInfo(data) {
  return request({
    url: targetUrl('/fuel/v2/getHelpInfo'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 提交加油订单
export function createOilOrder(data) {
  return request({
    url: targetUrl('/fuel/v2/order/createOilOrder'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 支付前确认信息
export function realPrice(data) {
  return request({
    url: targetUrl('/fuel/v2/realPrice'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 发起支付
export function rePay(data) {
  return request({
    url: targetUrl('/fuel/v2/order/rePay'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 加油get支付版本
export function getPayversion(data) {
  return request({
    url: targetUrl('/order/getPayVersion'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 我要贷款添加申请人
export function goLookLoans(data) {
  return request({
    url: lookLoan('/mall/createUserLoanRecord'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 我要贷款页面跳转
export function goLookLoanLink(data) {
  return request({
    url: lookLoan('/mall/getLoanLink'),
    method: 'get',
    params: data,
    prefix: 'activity'
  })
}
// 我要贷款判断用户是否录入信息
export function isLookLoan(data) {
  return request({
    url: lookLoan('/mall/loanUserCheck'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 本地生活首页icon
export function getLocalLifeHomeIcon(data) {
  return request({
    url: lookLoan('/mall/V4/rewrite/locationList'),
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡 banner
export function getBankListBanner(data) {
  return request({
    url: '/mall/getBankListBanner',
    method: 'get',
    params: data,
    prefix: ''
  })
}
// 银行列表
export function getBankListNew(data) {
  return request({
    url: '/mall/ws/getBankListNew',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 获取银行信息
export function getBankInfo(data) {
  return request({
    url: `/mall/ws/getBankInfo/${data}`,
    method: 'get'
    // params: data
  })
}
// 查询订单
export function getOrderList(data) {
  return request({
    url: '/mall/ws/getOrderList',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡获取用户申请信息
export function getAppInfo(data) {
  return request({
    url: '/mall/ws/getCardUserInfo',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡发送短信验证码
export function setSMSCode(data) {
  return request({
    url: '/mall/ws/sendMessage',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡添加修改用户信息
export function ModifyAppInfo(data) {
  return request({
    url: '/mall/ws/createUserInfo',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡创建订单
export function createOrder(data) {
  return request({
    url: '/mall/ws/createOrder',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 信用卡get订单
export function getCardOrderList(data) {
  return request({
    url: '/mall/ws/getOrderList',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 获取奖励说明
export function rewardDescription(data) {
  return request({
    url: '/mall/ws/rewardDescription',
    method: 'get',
    params: data,
    prefix: ''
  })
}
// 银行海报
export function generatePosterForBank(data) {
  return request({
    url: '/mall/V4/generatePosterForBank',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 生成海报
export function generatePoster(data) {
  return request({
    url: '/mall/V4/generatePoster',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 品牌折扣
export function getBrandDiscount(data) {
  return request({
    url: '/outside/shareJumpUrl',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 京东汽车用具品类推荐
export function getJdRecommendGoods(data) {
  return request({
    url: '/outside/getJdRecommendGoods',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 获取千猪链接
export function getLink(data) {
  return request({
    url: 'mall/qianzhu/v1/getLink',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 订单列表
export function getorderlist(data) {
  return request({
    url: '/order/list',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 美团订单
export function getMeiTuanOrder(data) {
  return request({
    url: '/app/outside/orderList',
    method: 'post',
    data,
    prefix: 'activity'
  })
}
// 手机充值订单
export function getMobileOrder(data) {
  return request({
    url: targetUrl('/rechargeMobile/order/list'),
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 视频充值订单
export function getVideoOrder(data) {
  return request({
    url: targetUrl('/vcard/orderList'),
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 大王卡卡种查询
export function getCardInfo(data) {
  return request({
    url: targetUrl('/api/kingCard/getKingCardList'),
    method: 'get',
    params: data
  })
}
// 大王卡获取验证码
export function getCardVerification(data) {
  return request({
    url: targetUrl('/api/kingCard/kcMessageSend'),
    method: 'post',
    data
  })
}
// 大王卡立即提交信息
export function setInfo(data) {
  return request({
    url: targetUrl('/api/kingCard/createOrder'),
    method: 'post',
    data
  })
}
// 大王卡验证验证码获取流水号
export function getCardVerificatio(data) {
  return request({
    url: targetUrl('/api/kingCard/kcMessageCheck'),
    method: 'post',
    data
  })
}
// 大王卡获取省市
export function getProvinceCity(data) {
  return request({
    url: targetUrl('/api/kingCard/getMailAddress'),
    method: 'get',
    param: data
  })
}
// 大王卡获取号码归属地
export function getCardAttribution(data) {
  return request({
    url: targetUrl('/api/kingCard/getEssAddress'),
    method: 'get',
    param: data
  })
}
// 大王卡选号
export function getCardList(data) {
  return request({
    url: targetUrl('/api/kingCard/numSelect'),
    method: 'post',
    data
  })
}
// get5G资费详情
export function get5GRates(data) {
  return request({
    url: targetUrl('/api/kingCard/getFirstMonthFee'),
    method: 'post',
    data
  })
}
// 获取王卡详情
export function getKingCardDetails(data) {
  return request({
    url: targetUrl('/api/kingCard/getKingCardDetails'),
    method: 'get',
    params: data
  })
}
// 肯德基url
export function getKFCUrl(data) {
  return request({
    url: '/mall/V3/getDiamondUrl ',
    method: 'post',
    data
  })
}
// 饿了么数据
export function getElmData(data) {
  return request({
    url: '/taobk/v1/createSmallProgramV2',
    method: 'post',
    data
  })
}
// 更多信用卡
export function getMoreBankList(data) {
  return request({
    url: '/mall/ws/getBankListMore',
    method: 'post',
    data
  })
}
// 海威电影票
export function getMovieUrl(data) {
  return request({
    url: '/mall/haiwei/v1/getLink',
    method: 'post',
    data
  })
}
// 品牌折扣
export function getBrandLink(data) {
  return request({
    url: '/haiwei/v1/product/TQLink',
    method: 'post',
    data
  })
}
// new手机充值订单列表
export function getOrderListNew(data) {
  return request({
    // url: targetUrl('/order/list'),
    url: '/order/list',
    method: 'post',
    data
  })
}
// new手机充值订单列表
export function getOrderv2List(data) {
  return request({
    url: targetUrlNew('/order/list'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/order/list',
    method: 'post',
    data
  })
}
// new取消订单
export function cancelOrder(data) {
  return request({
    url: targetUrl('/order/cancel'),
    method: 'post',
    data
  })
}
// new取消订单
export function cancelV2Order(data) {
  return request({
    url: targetUrlNew('/order/cancel'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/order/cancel',
    method: 'post',
    data
  })
}
// new视频列表banner
export function videoOrderBanner(data) {
  return request({
    url: targetUrl('/vcard/videoBanner?type=1'),
    method: 'get',
    params: data
  })
}
// new订单详情
// export function getVideoOrderDetail(data) {
//   return request({
//     url: targetUrl('/order/info'),
//     method: 'post',
//     data
//   })
// }
// new订单详情
export function getVideoOrderDetail(data) {
  return request({
    url: targetUrlNew('/order/info'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/order/info',
    method: 'post',
    data
  })
}
// 机票业务
// 全部城市列表
export function getCityList(data) {
  return request({
    url: '/plane/V2/getCityList',
    method: 'post',
    data
  })
}
// 搜索城市
export function searchCityList(data) {
  return request({
    url: '/plane/V2/searchCity',
    method: 'post',
    data
  })
}
// get航班列表
export function getTickedList(data) {
  return request({
    url: 'plane/V2/getFlightList',
    method: 'post',
    data
  })
}
// 取当前日期10天的时间
export function getDateTenList(data) {
  return request({
    url: '/plane/V2/getTenDayList',
    method: 'post',
    data
  })
}
// get行程详情
export function getTravelDetail(data) {
  return request({
    url: '/plane/V2/getFlightInfo',
    method: 'post',
    data
  })
}
// get产品说明
export function getProductDes(data) {
  return request({
    url: '/plane/V2/getRefundTicketRule',
    method: 'post',
    data
  })
}
// get乘机人列表
export function getTakeOppList(data) {
  return request({
    url: '/plane/V2/getTrainPassageList',
    method: 'post',
    data
  })
}
// get证件类型
export function getCardTypeList(data) {
  return request({
    url: '/plane/V2/getIdTypes',
    method: 'get',
    params: data
  })
}
// set乘机人
export function setTakeOpp(data) {
  return request({
    url: '/plane/V2/addTrainPassage',
    method: 'post',
    data
  })
}
// get单个乘机人信息
export function setOppInfo(data) {
  return request({
    url: '/plane/V2/getFirstTrainPassage',
    method: 'post',
    data
  })
}
// 删除联系人
export function delOppInfo(data) {
  return request({
    url: '/plane/V2/deleteTrainPassage',
    method: 'post',
    data
  })
}
// 生成订单
export function getTicketOder(data) {
  return request({
    url: '/plane/V2/generateOrder',
    method: 'post',
    data
  })
}
// 支付
export function payTicketOder(data) {
  return request({
    url: '/plane/V2/pay',
    method: 'post',
    data
  })
}
// 生成往返订单
export function payRoundTripTicketOder(data) {
  return request({
    url: '/plane/V2/generateRTOrder',
    method: 'post',
    data
  })
}
// 退票详情页(选人页)
export function getRefundDetail(data) {
  return request({
    url: '/plane/V2/getRefundTicketInfo',
    method: 'post',
    data
  })
}
// 提交退票
export function setRefund(data) {
  return request({
    url: '/plane/V2/submitRefundApplication',
    method: 'post',
    data
  })
}
// get退票手续费
export function getRefundFees(data) {
  return request({
    url: '/plane/V2/getRefundTicketPrice',
    method: 'post',
    data
  })
}
// get退改手续费
export function getRefundChangeFees(data) {
  return request({
    url: '/plane/V2/getHandlingFee',
    method: 'post',
    data
  })
}
// get改签详情（选人页）
export function getChangeDetail(data) {
  return request({
    url: '/plane/V2/getTicketChangesInfo',
    method: 'post',
    data
  })
}
// 改签航班列表
export function getChangeTicketList(data) {
  return request({
    url: '/plane/V2/getReschedulingFlights',
    method: 'post',
    data
  })
}
// 改签信息确认
export function ChangeInfoConfirmation(data) {
  return request({
    url: '/plane/V2/rerouteInfoCheck',
    method: 'post',
    data
  })
}
// 改签订单提交
export function ChangeOrderSub(data) {
  return request({
    url: '/plane/V2/submitReschedulingFlightsApply',
    method: 'post',
    data
  })
}

/**
 * 获取支付版本-灵活版本
 * 2021-05-13 18:22:46
 * @param data
 * @param data.moduleId 业务模块ID.1-视频充值 2-视频充值 3-机票支付 4-小桔加油支付
 */
export function fetchModulesPayParams(data) {
  return request({
    url: targetUrl('/order/getModulesPayParams'),
    method: 'post',
    data: data,
    prefix: 'jile'
  })
}

/**
 * 统一获取支付参数接口
 * 2021-05-13 18:22:46
 * @param data
 * @param data.moduleId 业务模块ID.1-视频充值 2-视频充值 3-机票支付 4-小桔加油支付
 * @param data.payType 支付方式 2-微信支付 4-支付宝支付 10-余额支付 5-小程序支付
 */
export function fetchPayParamsByModule(data) {
  return request({
    url: targetUrl('/order/getPayParams'),
    method: 'post',
    data: data,
    prefix: 'jile'
  })
}

/**
 * 获取手机充值创建订单
 * 2021-09-24 11:41:15
 * @param data
 * @param data.moduleId 业务模块ID.1-视频充值 2-视频充值 3-机票支付 4-小桔加油支付
 * @param data.payType 支付方式 2-微信支付 4-支付宝支付 10-余额支付 5-小程序支付
 */
export function fetchPayParamsByModules(data) {
  return request({
    url: targetUrlNew('/recharge/mobile/createMobileOrder'),
    method: 'post',
    data: data,
    prefix: 'jile'
  })
}

/**
 * 获取视频充值创建订单
 * 2021-09-24 11:41:11
 * @param data
 * @param data.moduleId 业务模块ID.1-视频充值 2-视频充值 3-机票支付 4-小桔加油支付
 * @param data.payType 支付方式 2-微信支付 4-支付宝支付 10-余额支付 5-小程序支付
 */
export function fetchPayParamsByVideo(data) {
  return request({
    url: targetUrlNew('/recharge/video/createVideoOrder'),
    method: 'post',
    data: data,
    prefix: 'jile'
  })
}

/**
 * 获取团油的列表
 * 2021-08-15 10:22:22
 * @author SnowRock
 */
export function fetchGroupRefuelingList(params) {
  return request({
    url: targetUrlNew('/fuel/ty/v1/list'),
    method: 'post',
    data: params,
    prefix: 'jile'
  })
}

/**
 * 获取团油的详情
 * 2021-08-15 10:22:22
 * @author SnowRock
 */
export function fetchGroupRefuelingDetail(params) {
  return request({
    url: targetUrlNew('/fuel/ty/v1/detail'),
    method: 'post',
    data: params,
    prefix: 'jile'
  })
}
/**
 * 团油支付
 * 2021-08-15 14:47:33
 * @author SnowRock
 */
export function fetchGroupRefuelingPay(params) {
  return request({
    url: targetUrlNew('/fuel/ty/v1/gasPay'),
    method: 'post',
    data: params,
    prefix: 'jile'
  })
}

/**
 * 获取渠道服务费提示语
 * 2021-08-15 14:47:33
 * @author SnowRock
 */
export function fetchChannelServiceTips() {
  return request({
    url: targetUrlNew('/fuel/xj/v1/getChannelServiceCharge'),
    method: 'get',
    prefix: 'jile'
  })
}

/**
 * 获取帮助列表
 * 2021-08-16 15:51:02
 * @author SnowRock
 */
export function fetchRefuelingHelpList() {
  return request({
    url: targetUrlNew('/fuel/ty/v1/getHelpList'),
    method: 'get',
    prefix: 'jile'
  })
}

/**
 * @description: 加油支付接口
 * @Date: 2021-09-16 18:55:44
 * @author: GJ
 */
export function getCheerPayModule(data) {
  return request({
    url: targetUrlNew('/pay/getPayModule'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/recharge/video/getInfo',
    method: 'post',
    data,
    prefix: ''
  })
}

/**
 * @description: 加油支付接口
 * @Date: 2021-09-16 18:55:44
 * @author: GJ
 */
export function getCheerPayParams(data) {
  return request({
    url: targetUrlNew('/pay/getPayParams'),
    // url: 'https://gateway.yueshang.shop/openlocation/v2/recharge/video/getInfo',
    method: 'post',
    data,
    prefix: ''
  })
}
