<template>
  <div class="content">
    <div class="titleWrap">
      <div class="titleText">
        <p>{{ startAddress }}</p>
        <img
          :src="tripType === 'OW' ? 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-27/21/yuelvhuiHEeDUSY4uW1609075234.png' : 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/15/yuelvhuiwYaqChQanm1609141435.png'"
          alt=""
        >
        <p>{{ endAddress }}</p>
      </div>
    </div>
    <!--        去程-->
    <div class="tickedTimeDetail">
      <div class="AviationInfo">
        <p><span>{{ TravelText1 }}</span></p>
        <img :src="priceInfoD.flightCompanyLogo" alt="" style="margin-left: 28px;margin-top: -5px;">
        <p>{{ priceInfoD.flightCompanyName }}{{ priceInfoD.fn }}</p>
        <p>{{ priceInfoD.date }}</p>
        <p>{{ priceInfoD.weekDate }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ priceInfoD.departureDayTime }}</p>
          <p>{{ priceInfoD.departureAirport }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ priceInfoD.arrivalDayTime }}</p>
          <p>{{ priceInfoD.arrivalAirport }}</p>
        </div>
      </div>
      <div class="GiftInfo">
        {{ priceInfoD.haveMealName }} | {{ priceInfoD.planeCode }}
      </div>
    </div>
    <!--        返程-->
    <div class="tickedTimeDetail2">
      <div class="AviationInfo">
        <p><span>{{ TravelText2 }}</span></p>
        <img :src="priceInfoB.flightCompanyLogo" alt="" style="margin-left: 28px;margin-top: -5px;">
        <p>{{ priceInfoB.flightCompanyName }}{{ priceInfoB.fn }}</p>
        <p>{{ priceInfoB.date }}</p>
        <p>{{ priceInfoB.weekDate }}</p>
      </div>
      <div class="TimeInfo">
        <div class="stareTimeInfo">
          <p>{{ priceInfoB.departureDayTime }}</p>
          <p>{{ priceInfoB.departureAirport }}</p>
        </div>
        <div class="TimeImg">
          <img
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/21/yuelvhuiLoDKVslO4A1609163787.png"
            alt=""
          >
        </div>
        <div class="endTimeInfo">
          <p>{{ priceInfoB.arrivalDayTime }}</p>
          <p>{{ priceInfoB.arrivalAirport }}</p>
        </div>
      </div>
      <div class="GiftInfo">
        {{ priceInfoB.haveMealName }} | {{ priceInfoB.planeCode }}
      </div>
      <div class="RoundTripTips">
        <p>此价格为往返组合产品：<span>支付成功后将分开出票。出票后，如其中一段发生航班变动，另一段行程的改签费用需旅客自行承担。</span></p>
      </div>
    </div>
    <!--        详情列表-->
    <div class="tickedTimePriceDetail">
      <van-tabs v-if="tabList.length > 1" v-model="active" sticky @click="tabClick">
        <van-tab v-for="(tabItem,tabIndex) in tabList" :key="tabIndex" :title="item.name">
          <div class="tickedList">
            <div
              v-for="(item,index) in tickedList"
              :key="index"
              class="tickedListExternal"
              :style="{backgroundImage:'url('+bgc+')'}"
            >
              <div class="tickedListLeft">
                <div class="tickedListLeftTitle">
                  <p class="tickedListPrice">¥<span>{{ item.price }}</span></p>
                  <div>
                    <p>{{ item.prdSubTypeName }}</p>
                    <p>{{ item.prdSubTypeTicketingTime }}</p>
                  </div>
                </div>
                <!--                                儿童票-->
                <div v-if="item.childPrice !== ''" class="isChild">
                  <p><span>儿童票<span class="ChildPrice">¥{{ item.childPrice }}</span></span></p>
                </div>
                <div
                  class="tickedListLeftContent"
                  :class="item.childPrice !== '' ? 'tickedListLeftContent1' :'tickedListLeftContent2'"
                  @click="showNote(item)"
                >
                  <p>
                    <span>{{ item.baseCabinName }}{{ item.discount }}折 | 退改¥{{ (item.refundExp)[0].price }}起 | </span>
                    <span v-if="item.weight === 0">无免费托运行李额</span>
                    <span v-else>托运行李{{ item.weight }}KG</span>
                  </p>
                  <img
                    src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
                    alt=""
                  >
                </div>
              </div>
              <div class="tickedListRight" @click="goReservation(item)">
                <p>预订</p>
                <p><span>剩余{{ item.vote }}张</span></p>
              </div>
            </div>
            <!--                        更多-->
            <div class="tickedListMore" />
          </div>
        </van-tab>
      </van-tabs>
      <div v-else class="tickedList">
        <div
          v-for="(item,index) in tickedList"
          :key="index"
          class="tickedListExternal"
          :style="{backgroundImage:'url('+bgc+')'}"
        >
          <div class="tickedListLeft">
            <!--                        票价-->
            <div class="tickedListPrice">
              <p>¥<span>{{ item.price }}</span></p>
              <p>儿童 <span>¥{{ item.childPrice }}</span></p>
            </div>
            <!--                        去程-->
            <div class="tickedListTake" @click="showNote(item,0)">
              <p class="tickedListTakeTitle"><span>去</span></p>
              <p class="tickedListTakeInfo">
                <span>
                  {{ item.trip.baseCabinName }}{{ item.trip.discount }}折 | 退改¥{{ (item.trip.refundExp)[0].price }}起
                </span>
                <span v-if="item.trip.weight === 0">| 无免费托运行李额</span>
                <span v-else>| 托运行李{{ item.trip.weight }}KG | </span>
                <span>{{ item.trip.prdSubTypeName }} | {{ item.trip.prdSubTypeTicketingTime }}</span>
                <img
                  src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
                  alt=""
                >
              </p>
            </div>
            <!--                        返程-->
            <div class="tickedListBack" @click="showNote(item,1)">
              <p class="tickedListBackTitle"><span>返</span></p>
              <p class="tickedListBackInfo">
                <span>
                  {{ item.back.baseCabinName }}{{ item.back.discount }}折 | 退改¥{{ (item.back.refundExp)[0].price }}起
                </span>
                <span v-if="item.back.weight === 0">| 无免费托运行李额</span>
                <span v-else>| 托运行李{{ item.back.weight }}KG | </span>
                <span>{{ item.back.prdSubTypeName }} | {{ item.back.prdSubTypeTicketingTime }}</span>
                <img
                  src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/23/yuelvhuik6S77Dgbb31609169525.png"
                  alt=""
                >
              </p>
            </div>
          </div>
          <div class="tickedListRight" @click="goReservation(item)">
            <p>预订</p>
            <p><span>剩余{{ item.vote }}张</span></p>
          </div>
        </div>
        <!--                        更多-->
        <div class="tickedListMore" />
      </div>
    </div>

    <!--        展示说明-->
    <van-action-sheet v-model="showDisplayActive">
      <!--            <van-tabs v-model="DisplayActives" scrollspy sticky class="DisplayActiveWrap">-->
      <!--                <van-tab :title="item.value" v-for="(item,index) in DisplayActivesList" :key="index">-->
      <van-tabs v-model="DisplayActive" scrollspy sticky class="DisplayActiveWrap">
        <van-tab title="票价明细" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuix6FjAWywIu1609225209.png"
              alt=""
            >
            <p>票价明细</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ adtPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <p>机票¥{{ chdPrice }}</p>
              <div class="van-hairline--left" />
              <p>{{ CabinName }}</p>
            </div>
          </div>
          <div class="TicketRemarks">
            <p>【儿童票】年龄在2周岁（含）-12周岁（不含）的常客，可预订
              儿童票</p>
          </div>
        </van-tab>
        <van-tab title="行李规定" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhui5fqqBtMpBX1609225230.png"
              alt=""
            >
            <p>免费行李规定</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}KG</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div v-if="chdBaggage !== undefined" class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="TicketBaggageContentInfo">
                <div class="chdBaggageInfo">
                  <div class="chdBaggageInfoT1">免费托运{{ consign }}</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2" />
                </div>
                <div class="van-hairline--bottom" />
                <div class="adtBaggageInfo">
                  <div class="chdBaggageInfoT1">免费手提行李{{ portable }}KG每人一件</div>
                  <div class="van-hairline--left" />
                  <div class="chdBaggageInfoT2">单件体积不超过{{ volume }}</div>
                </div>
                <div class="van-hairline--bottom" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="退改签说明" class="DisplayActiveTab">
          <div class="TicketTitle">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>退订收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdRefundFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>同舱改期收费规则</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>成人</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in adtConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p>儿童</p>
              <div class="van-hairline--left" />
              <div class="" style="width:274px ">
                <div v-for="(item,index) in chdConcessionFeeList" :key="index" class="">
                  <div style="display: flex;">
                    <p style="width: 170px;">{{ item.date }}</p>
                    <div class="van-hairline--left" />
                    <p>¥{{ item.amount }}</p>
                  </div>
                  <div class="van-hairline--bottom" />
                </div>
              </div>
            </div>
          </div>
          <div class="TicketTitle" style="margin-top: 16px">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-29/15/yuelvhuiakdZNmJN0J1609225245.png"
              alt=""
            >
            <p>签转他航条件</p>
          </div>
          <div class="TicketContent">
            <div class="TicketContentTitle">
              {{ startAddress }}-{{ endAddress }}
            </div>
            <div class="van-hairline--bottom" />
            <div class="TicketContentInfo">
              <p style="width: 100%">{{ isChange }}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <!--                </van-tab>-->
      <!--            </van-tabs>-->
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ActionSheet, List, Loading, Sidebar, SidebarItem, Tab, Tabs, Toast, TreeSelect
} from 'vant'

Vue.use(Tab)
  .use(Tabs)
  .use(Loading)
  .use(Toast)
  .use(ActionSheet)
  .use(Sidebar)
  .use(SidebarItem)
  .use(TreeSelect)
  .use(List)

import {
  getTravelDetail,
  getProductDes
} from '@/services/comeOn.js'

export default {
  name: 'RoundTripTickedDetail',
  data() {
    return {
      startAddress: '',
      endAddress: '',
      tripType: '',
      TravelText1: '',
      TravelText2: '',
      priceInfo: [],
      priceInfoD: {}, // 去程
      priceInfoB: {}, // 返程
      active: 0,
      tabList: [],
      tickedList: [],
      bgc: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-28/22/yuelvhuiPcGklV4a6g1609167171.png',
      RemainingTickets: '', // 余票
      showDisplayActive: false,
      DisplayActive: 0,
      DisplayActives: 0,
      DisplayActivesList: [{
        code: 0,
        value: '去程'
      }, {
        code: 1,
        value: '返程'
      }],
      CabinName: '',
      adtPrice: '',
      chdPrice: '',
      adtBaggageInfo: [], // 成人行李规定
      chdBaggageInfo: [], // 儿童行李规定
      consign: '',
      portable: '',
      volume: '',
      chdBaggage: '',
      adtRefundFeeList: [],
      chdRefundFeeList: [],
      adtConcessionFeeList: [],
      chdConcessionFeeList: [],
      isChange: '',
      TravelLogo: '', // 航空logo
      rtFlightInfoId: ''// 返程航班号码
    }
  },
  created() {
    this.startAddress = this.$route.query ? this.$route.query.startAddress : this.$route.query.startAddress
    this.endAddress = this.$route.query ? this.$route.query.endAddress : this.$route.query.endAddress
    this.flightinfoid = this.$route.query ? this.$route.query.flightInfoId : this.$route.query.flightInfoId
    this.childNum = this.$route.query ? this.$route.query.childNum : this.$route.query.childNum
    this.tripType = this.$route.query ? this.$route.query.tripType : this.$route.query.tripType
    if (this.tripType === 'RT') {
      this.TravelText1 = '去程'
      this.TravelText2 = '返程'
    }
    this.rtFlightInfoId = this.$route.query ? this.$route.query.rtFlightInfoId : this.$route.query.rtFlightInfoId// 返程航班号码
    this.getTravelDetail()
  },
  mounted() {

  },
  methods: {
    getTravelDetail() {
      getTravelDetail({
        flightInfoId: this.flightinfoid, // foid
        childNum: 1, // 儿童票 1/0
        tripType: this.tripType, // 行程类型 OW单程RT往返
        dataExt: {
          rtFlightInfoId: this.rtFlightInfoId// 返程foid
        }
      }).then(res => {
        if (Number(res.code) === 200) {
          this.flightInfo = res.data.flightInfo
          // 去程
          const depart = res.data.flightInfo.depart
          this.priceInfoD = depart
          // 返程
          const back = res.data.flightInfo.back
          this.priceInfoB = back

          // this.haveMeal = depart.haveMeal;//是否有餐食
          // this.departureTerminal = depart.departureTerminal;//出发航站楼
          // this.arrivingTerminal = depart.arrivingTerminal;//目的航站楼
          for (let i = 0; i < res.data.priceInfo.length; i++) {
            const tabList = {}
            tabList.number = i
            tabList.name = (res.data.priceInfo)[i].baseCabinName
            this.tabList.push(tabList)
            res.data.priceInfo[i].isShow = false
          }
          this.priceInfo = (res.data.priceInfo)[0].list
          this.tickedList = (res.data.priceInfo)[0].list// 默认展示第一组数据
        }
      })
    },
    // 切换舱位时
    tabClick(name, title) {
      this.tickedList = (this.priceInfo)[name].list
    },
    // 展示说明
    showNote(item, type) {
      let priceInfoId
      if (type === 0) {
        priceInfoId = item.trip.priceInfoId
      } else if (type === 1) {
        priceInfoId = item.back.priceInfoId
      }
      getProductDes({
        flightInfoId: this.flightinfoid,
        tripType: this.tripType,
        priceInfoId: priceInfoId
      }).then(res => {
        if (Number(res.code) === 200) {
          this.CabinName = res.data.chd.CabinName// 舱位
          this.adtPrice = res.data.adt.ticketPrice// 成人票价
          this.chdPrice = res.data.chd.ticketPrice// 儿童票价
          this.consign = res.data.adt.luggage.consign// 成人托运行李
          this.portable = res.data.adt.luggage.portable// 成人手提行李
          this.volume = res.data.adt.luggage.volume// 成人手提行李面积
          // 儿童行李标准
          this.chdBaggage = res.data.chd.luggage
          this.adtRefundFeeList = res.data.adt.refundCondition// 成人退票收费list
          this.chdRefundFeeList = res.data.chd.refundCondition// 儿童退票收费标准
          this.adtConcessionFeeList = res.data.adt.rerouteCondition// 成人同舱改期收费规则
          this.chdConcessionFeeList = res.data.chd.rerouteCondition// 儿童同舱改期收费规则
          this.isChange = res.data.chd.transferRule// 改签说明
          this.showDisplayActive = true// 显示产品说明
        }
      })
    },
    // 预订
    goReservation(item) {
      this.$store.state.ticketListInfo = this.flightInfo
      this.$store.state.ticketPriceInfo = item
      this.$router.push({
        path: '/returnReservationTicket',
        query: {
          startAddress: this.startAddress,
          endAddress: this.endAddress,
          tripType: this.tripType, // 行程状态
          childNum: this.isChildren// 儿童票 1/0

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  /*height: 100%;*/
  background-color: #f8f8f8;
}

.content {
  position: absolute;

  .titleWrap {
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align: center;
    line-height: 42px;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1;

    .titleText {
      display: flex;
      line-height: 42px;

      p {
        font-size: 17px;
        color: #333333;
        font-weight: 600;
      }

      img {
        width: 16px;
        height: 5px;
        margin: 17px 8px;
      }

    }
  }

  .tickedTimeDetail, .tickedTimeDetail2 {
    padding: 31px 23px;
    background-color: #fff;
    margin-top: 43px;

    .AviationInfo {
      display: flex;

      p {
        line-height: 17px;
        font-size: 13px;
        color: #333333;
      }

      p:nth-child(1) {
        width: 32px;
        height: 17px;
        text-align: center;
        background-color: #E33F44;
        color: #ffffff;
        border-radius: 3px;

        span {
          display: inline-block;
          transform: scale(0.8);
        }
      }

      p:nth-child(2) {
        margin-left: 28px;
      }

      p:nth-child(3) {
        margin-left: 10px;
      }

      p:nth-child(4) {
        margin-left: 10px;
      }
    }

    .TimeInfo {
      display: flex;
      margin-top: 20px;

      .stareTimeInfo, .endTimeInfo {
        color: #333333;
        flex: 1;

        p:nth-child(1) {
          font-size: 29px;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 12px;
        }
      }

      .TimeImg {
        flex: 1;

        img {
          width: 52px;
          height: 6px;
          margin: 14px 28px;
        }
      }
    }

    .GiftInfo {
      width: 100%;
      text-align: center;
      margin-top: 17px;
      font-size: 12px;
      color: #999999;
    }
  }

  .tickedTimeDetail2 {
    margin-top: 0;
  }

  .RoundTripTips {
    width: 100%;
    height: 67px;
    margin-top: 18px;
    background-color: #F8F8F8;
    border-radius: 7px;
    padding: 12px 13px;
    text-align: left;
    line-height: 17px;

    p {
      font-size: 13px;
      color: #E33F44;

      span {
        color: #333333;
      }
    }
  }

  .tickedTimePriceDetail {
    width: 100%;
    margin-top: 10px;

    .tickedList {
      width: 100%;

      .tickedListExternal {
        width: 100%;
        /*height: 107px;*/
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        padding: 30px 20px 21px 20px;

        .tickedListLeft {
          /*width: 70%;*/
          text-align: left;

          .tickedListPrice {
            display: flex;

            p:nth-child(1) {
              font-size: 14px;
              color: #E33F44;
              font-weight: 600;

              span {
                font-size: 23px;
                margin-left: 4px;
              }
            }

            p:nth-child(2) {
              margin-left: 13px;
              font-size: 12px;
              margin-top: 10px;
              color: #000000;

              span {
                color: #E33F44;
              }
            ;
            }
          }

          .tickedListTake, .tickedListBack {
            display: flex;
            margin-top: 15px;

            p:nth-child(1) {
              width: 15px;
              height: 15px;
              font-size: 12px;
              border: 1px solid #999999;
              text-align: center;
              line-height: 15px;
              border-radius: 2px;
              color: #999999;

              span {
                display: inline-block;
                font-size: 12px;
                transform: scale(0.8);
              }
            }

            p:nth-child(2) {
              display: flex;
              flex-wrap: wrap;
              margin-left: 9px;
              font-size: 12px;
              color: #333333;

              span {
                font-size: 12px;
                display: inline-block;
                transform: scale(0.9);
              }

              img {
                width: 5px;
                height: 9px;
                margin-top: 3px;
              }
            }
          }

          .tickedListBack {
            display: flex;

          }
        }

        .tickedListRight {
          margin-left: auto;
          border-radius: 3px;
          text-align: center;
          margin-top: 8px;

          p:nth-child(1) {
            width: 45px;
            height: 30px;
            background-color: #E33F44;
            color: #ffffff;
            line-height: 35px;
            font-size: 15px;
            border-radius: 3px 3px 0 0;
          }

          p:nth-child(2) {
            width: 45px;
            height: 15px;
            background-color: #fff;
            color: #E33F44;
            line-height: 15px;
            border: 1px solid #E33F44;
            border-radius: 0 0 3px 3px;
            text-align: left;
            font-size: 12px;

            span {
              display: inline-block;
              transform: scale(0.7);
            }
          }
        }
      }
    }
  }

  .DisplayActiveWrap {
    padding: 16px 20px;

    .DisplayActiveTab {
      padding-top: 16px;
    }

    .TicketTitle {
      display: flex;

      img {
        width: 13px;
        height: 13px;
      }

      p {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
        margin-left: 10px;
      }
    }

    .TicketContent {
      width: 100%;
      margin-top: 11px;
      border-radius: 3px;
      border: 1px solid #E8E8E8;
      text-align: left;

      .TicketContentTitle {
        width: 100%;
        border-radius: 3px 3px 0 0;
        background-color: #f8f8f8;
        font-size: 13px;
        color: #333333;
        padding: 13px 14px;
        font-weight: 500;
      }

      .TicketContentInfo {
        width: 100%;
        display: flex;

        p {
          font-size: 13px;
          padding-left: 14px;
          height: 40px;
          line-height: 40px;
        }

        p:nth-child(1) {
          width: 53px;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(3), p:nth-child(5) {
          color: #666666;
        }

        p:nth-child(3) {
          width: 155px;
        }

        p:nth-child(5) {
          width: 104px;
        }
      }

      /*行李info*/

      .TicketBaggageContentInfo {
        /*display: flex;*/

        .chdBaggageInfo, .adtBaggageInfo {
          display: flex;

          .chdBaggageInfoT1 {
            width: 149px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }

          .chdBaggageInfoT2 {
            width: 117px;
            line-height: 20px;
            padding-left: 7px;
            font-size: 13px;
            color: #666666;
          }
        }

        .chdBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1 {
            line-height: 40px;
          }
        }

        .adtBaggageInfo, .chdBaggageInfoT2 {
          .chdBaggageInfoT1, .chdBaggageInfoT2 {
            line-height: 30px;
          }
        }
      }
    }

    .TicketRemarks {
      width: 100%;
      margin-top: 11px;
      text-align: left;
      line-height: 15px;

      p {
        font-size: 12px;
        color: #666666;
      }
    }

    //退改签info

  }
}
</style>
